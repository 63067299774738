import { formatFiltersFromQS } from '@catalogo/core-filters';
import { stringify } from 'query-string';
import { getIn } from 'seamless-immutable';

const mountFilterQsTag = ({ filterQs, tag }) => {
  if (filterQs.length) {
    return [...filterQs, JSON.stringify({ id: 'tag', value: tag, fixed: true })];
  }
  return [JSON.stringify({ id: 'tag', value: tag, fixed: true })];
};

export const mountFilters = (parsedQs, params) => {
  const { tag } = params;

  const filters = getIn(parsedQs, ['filter']) ? getIn(parsedQs, ['filter'], []) : getIn(parsedQs, ['filtro'], []);

  const filtersArr = Array.isArray(filters) ? filters : [filters];

  const filterName = Object.keys(parsedQs)
    .filter(qs => qs === 'filter' || qs === 'filtro')
    .join('');
  const filterQs = filtersArr
    .filter(filter => !!(JSON.parse(filter).id !== 'tag'))
    .map(filter => JSON.stringify({ ...JSON.parse(filter), fixed: false }));

  const filterQsTag = mountFilterQsTag({ filterQs, tag });

  const qs = { ...parsedQs, [filterName ? filterName : 'filter']: filterQsTag };

  return formatFiltersFromQS(`?${stringify(qs)}`);
};

const hasCount = options => options.some(({ count }) => count > 0);

export const filterOptions = aggregations =>
  aggregations.filter(agg => agg.options && !!agg.options.length && !!hasCount(agg.options));

export const createSearch = data => {
  if (!data) {
    return data;
  }
  return {
    search: {
      ...data.search,
      aggregations: filterOptions(getIn(data, ['search', 'aggregations'], [])),
    },
  };
};
