import { useLocation } from 'react-router-dom';
import React from 'react';
import { parse } from 'query-string';

const sortByList = sortBy => [
  { name: 'Menores Preços', term: sortBy === 'lowerPrice' ? 'lowerPrice' : 'lowerPriceRelevance' },
  { name: 'Maiores Preços', term: sortBy === 'higherPrice' ? 'higherPrice' : 'higherPriceRelevance' },
  { name: 'Mais Relevantes', term: 'relevance' },
  { name: 'Mais Vendidos', term: 'topSelling' },
  { name: 'Melhor Avaliados', term: 'rating' },
];

export const withNavigation = Component => props => {
  const { search } = useLocation();
  const qs = parse(search);
  const sortBy = qs?.['sortBy'] || qs?.['ordenacao'] || 'relevance';

  const navigation = {
    sortByList: sortByList(sortBy),
    navigation: {
      sortBy,
    },
  };
  return <Component {...props} {...navigation} />;
};
