import { stringify } from 'querystring';
import React from 'react';
import styled from 'styled-components';

const SortBy = ({ history, parsedQs }) => {
  const order = parsedQs?.sortBy || parsedQs?.ordenacao || 'relevance';

  const setSelectedOption = e => {
    const newQueryString = {
      ...parsedQs,
      sortBy: e.target.value,
    };

    history.replace(`?${stringify(newQueryString)}`);
  };

  const orders = [
    { key: order === 'lowerPrice' ? 'lowerPrice' : 'lowerPriceRelevance', value: 'menores preços' },
    { key: order === 'higherPrice' ? 'higherPrice' : 'higherPriceRelevance', value: 'maiores preços' },
    { key: 'relevance', value: 'mais relevantes' },
    { key: 'topSelling', value: 'mais vendidos' },
    { key: 'rating', value: 'mais avaliados' },
  ];

  return (
    <SortBar>
      <Text>ordenar por: </Text>
      <Select id="sort-by" name="sort-by" defaultValue={order} onChange={setSelectedOption}>
        {orders.map(({ key, value }) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </Select>
    </SortBar>
  );
};

const SortBar = styled.form`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.grey.light};
  border-radius: 4px;
  padding: 10px 0 12px 12px;

  &::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 13px;
    border: solid ${({ theme }) => theme.grey.dark};
    border-width: 0 2px 2px 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
  }
`;

const Select = styled.select`
  min-width: 150px;
  background: transparent;
  color: ${({ theme }) => theme.grey.dark};
  font-size: ${({ theme }) => theme.fontSize.sm};
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  padding-left: 5px;
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 600;
`;

export default SortBy;
