import { stringify } from 'querystring';
import React from 'react';
import styled from 'styled-components';
import { removeUndef } from '@catalogo/core-helpers/utils';
import { Nav } from '@catalogo/core-nav';
import { getIn } from 'seamless-immutable';
import Checkbox from './components/checkbox';
import Rating from './components/rating';

const Aggregation = ({ id, title, options, history, rc, context, modalHash }) => {
  const location = getIn(history, ['location'], {});
  if (!options) {
    return null;
  }
  const limit = 7;
  const optionsFiltered = options.slice(0, limit - 1);

  const openMoreFilters = filterId => {
    const { push } = history;
    const { pathname, search } = location;

    push({
      pathname,
      search,
      hash: `#modal-${modalHash}`,
      state: { filterId },
    });
  };
  const renderField = option =>
    id === 'avaliacao' || id === 'rating' ? <Rating {...option} /> : <Checkbox {...option} />;

  return (
    <ContainerAggregation>
      <Title>{title}</Title>
      {optionsFiltered.map(option => {
        const additionalQS = stringify(removeUndef({ rc, context }));
        const href = `${option.href}${additionalQS ? `&${additionalQS}` : ''}`;
        return (
          <NavUI key={`${id}-${option.title}`} to={href}>
            {renderField(option)}
          </NavUI>
        );
      })}

      {options.length > limit && (
        <SeeMore
          onClick={() => {
            openMoreFilters(id);
          }}
        >
          ver todos
        </SeeMore>
      )}
    </ContainerAggregation>
  );
};

const ContainerAggregation = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grey.light};
  padding: 8px 0;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-transform: lowercase;
  color: ${({ theme }) => theme.grey.dark};
  display: block;
  padding: 10px 0px;
`;

const NavUI = styled(Nav)`
  display: block;
  padding: 6px 0;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const SeeMore = styled.button`
  display: block;
  font-size: 14px;
  text-decoration: underline;
  padding: 6px 0;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.grey.dark};
  cursor: pointer;
`;

export default Aggregation;
