import React from 'react';
import { Redirect } from '@catalogo/core-redirect';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import { IWithNotFoundRedirectProps } from './types';

export const withNotFoundRedirect = (Component: React.FC<IWithNotFoundRedirectProps>) => (
  props: IWithNotFoundRedirectProps
) => {
  const { history, location, match, data } = props;

  const hasNoProductIds = data?.search?.productIds?.length === 0;
  const isSSR = typeof window === 'undefined';

  if (hasNoProductIds && isSSR) {
    return <Redirect status={404} url={location?.pathname} history={history} location={location} match={match} />;
  }

  return <Component {...props} />;
};

export default compose(withRouter, withNotFoundRedirect);
