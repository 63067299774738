import React from 'react';
import compose from 'lodash/fp/compose';
import { withMetrics } from '@catalogo/core-metrics';
import { withNavigation } from '@catalogo/core-connect/navigation';
import { useHotsite } from './hooks/hotsite';
import { WithHotsiteProps } from './types';

export const withHotsite = (Component: React.FC) => ({
  routeId,
  parsedQs,
  match,
  dispatchMetrics,
  history,
  sortByList,
  ...props
}: WithHotsiteProps) => {
  const { publications, loading, error, refetch, data, fetchMore } = useHotsite({
    routeId,
    parsedQs,
    match,
    dispatchMetrics,
  });

  const products = data?.search?.products || [];
  const aggregations = data?.search?.aggregations || [];
  const cleanAll = data?.search?.cleanAll || '/';
  const selectedAggregations = data?.search?.selectedAggregations || [];
  const { h1, name } = data?.page || {};

  const componentProps = {
    publications,
    loading,
    error,
    refetch,
    routeId,
    title: h1 || name,
    data,
    products,
    dispatchMetrics,
    fetchMore,
    parsedQs,
    aggregations,
    cleanAll,
    selectedAggregations,
    history,
    sortByList,
  };

  return <Component {...componentProps} {...props} />;
};

export default compose(withMetrics, withNavigation, withHotsite);
