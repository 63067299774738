import React, { Component } from 'react';
import styled from 'styled-components';

export class Grid extends Component {
  render() {
    return <StyledGrid {...this.props} />;
  }
}

const StyledGrid = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${props => props.justifyContent || `space-between`};
  flex-wrap: wrap;
`;
