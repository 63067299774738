import { stringify } from 'querystring';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Arrow from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import { getIn } from 'seamless-immutable';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: null };
  }

  componentDidMount() {
    const { offset, limit } = this.props;
    this.setState({ currentPage: this.getcurrentPage(offset, limit) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.offset !== this.props.offset) {
      const { offset, limit } = this.props;
      this.setState({ currentPage: this.getcurrentPage(offset, limit) });
    }
  }

  getcurrentPage(offset, limit) {
    return offset === 0 ? 1 : offset / limit + 1;
  }

  getPageLink(label) {
    const { queryStringPage, parsedQs, history, limit } = this.props;

    const newQueryString = {
      ...parsedQs,
      page: label,
      limit: limit,
      offset: (label - 1) * limit,
    };

    const newQueryStringPage = {
      ...parsedQs,
      page: label,
    };

    return `${history?.location?.pathname}?${stringify(queryStringPage ? newQueryStringPage : newQueryString)}`;
  }

  handleClick(e, label, isDisabled) {
    e?.preventDefault();

    if (!isDisabled) {
      const { history, dispatchMetrics, setPageCallback } = this.props;

      dispatchMetrics && dispatchMetrics('event:track', { action: 'ver-mais-produtos-busca-desk-old' });

      if (setPageCallback) {
        setPageCallback(label);
      }

      const url = this.getPageLink(label);

      history.push(url);
    }
  }

  getNavigation() {
    const { limit, total } = this.props;
    if (!limit) {
      return null;
    }
    const { currentPage } = this.state;
    const totalPages = Math.ceil(total / limit);

    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push({ label: number, active: number === currentPage });
    }
    return items;
  }

  getVisibleItems(pagesItems) {
    const { currentPage } = this.state;
    const { maxButtons } = this.props;

    let firstItem = 0;

    let lastItem = maxButtons;
    const middleButton = Math.ceil(maxButtons / 2);
    if (currentPage > middleButton) {
      lastItem = Math.min(currentPage + middleButton - 1, pagesItems.length);
      firstItem = Math.max(lastItem - maxButtons, 0);
    }

    return pagesItems.slice(firstItem, lastItem);
  }

  render() {
    const pagesItems = this.getNavigation();
    if (!getIn(pagesItems, [1], null)) {
      return null;
    }

    const { currentPage } = this.state;
    const slicedItems = this.getVisibleItems(pagesItems);
    return (
      <Container>
        <Items>
          <li>
            <PageLink
              href={this.getPageLink(currentPage - 1)}
              onClick={e => this.handleClick(e, currentPage - 1, currentPage === 1)}
              $disabled={currentPage === 1}
            >
              <ArrowRotate width="10" height="10" fill="#999" rotate="90" />
            </PageLink>
          </li>
          {slicedItems.map(page => (
            <li key={`${page.label}`}>
              <PageLink
                href={this.getPageLink(page.label)}
                onClick={e => this.handleClick(e, page.label)}
                $active={page.active}
              >
                {page.label}
              </PageLink>
            </li>
          ))}
          <li>
            <PageLink
              href={this.getPageLink(currentPage + 1)}
              onClick={e => this.handleClick(e, currentPage + 1, currentPage === pagesItems.length)}
              $disabled={currentPage === pagesItems.length}
            >
              <ArrowRotate width="10" height="10" fill="#999" rotate="270" />
            </PageLink>
          </li>
        </Items>
      </Container>
    );
  }
}

Pagination.propTypes = {
  offset: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  maxButtons: PropTypes.number,
  history: PropTypes.object,
  parsedQs: PropTypes.object,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 12px;
`;

const Items = styled.ul`
  display: flex;
`;

const ArrowRotate = styled(Arrow)`
  ${({ rotate }) => rotate && `transform: rotate(${rotate}deg)`}
`;

const PageLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  color: ${({ theme }) => theme.grey.medium};

  &:hover {
    color: ${({ theme }) => theme.grey.medium};
  }

  ${({ $active }) =>
    $active &&
    css`
      color: #000;
      font-weight: bold;
      outline: none;
      background-color: #f1f1f1;

      &:hover {
        color: #000;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      border-color: #ccc;
      color: #ccc;
      opacity: 0.3;
      cursor: default;
    `}
`;

export default Pagination;
