import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Checkbox = ({ params, title, filtered, onClick }) => (
  <Container id={params.id} onClick={onClick}>
    <div>{title}</div>
    <Checkmark filtered={filtered} />
  </Container>
);

Checkbox.propTypes = {
  title: PropTypes.string,
  filtered: PropTypes.bool,
  count: PropTypes.number,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  title: '',
  filtered: false,
  count: 0,
  id: '',
  onClick: () => {},
};

export default Checkbox;

const Checkmark = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -9px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #707070;
  transition-duration: 220ms;
  cursor: pointer;

  ${({ filtered, theme }) =>
    filtered &&
    css`
      background-color: ${theme.primaryColor};
      border-color: ${theme.primaryColor};
    `}

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    ${({ filtered }) =>
      filtered &&
      css`
        display: block;
      `}
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  user-select: none;
  ${({ id }) =>
    id !== 'preco_salesolutions' &&
    id !== 'price_salesolutions' &&
    css`
      text-transform: lowercase;
    `}

  div {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;
