import { stringify } from 'querystring';
import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { removeUndef } from '@catalogo/core-helpers/utils';
import { Nav } from '@catalogo/core-nav';

const Category = ({ id, title, options, rc, context }) => {
  const [showMore, setShowMore] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);
  const limit = 14;
  const optionsFiltered = showMore ? options : options?.slice(0, limit);

  useEffect(() => {
    setContainerHeight(containerRef.current.scrollHeight);
  }, [showMore]);

  const openMoreFilters = () => {
    setShowMore(!showMore);
  };

  const renderSeeMore = options => {
    const shouldRenderSeeMoreCategory = options[0]?.options?.length > limit;
    const shouldRenderSeeMoreSearch = options?.length > limit;

    if (shouldRenderSeeMoreCategory || shouldRenderSeeMoreSearch) {
      return <SeeMore onClick={openMoreFilters}>{showMore ? 'ocultar' : 'ver todos'}</SeeMore>;
    }
    return null;
  };

  const renderCategory = (option, href, additionalQS) =>
    !option.options ? (
      <NavUI key={`${id}-${option.title}`} to={href}>
        <Categoria>
          <span>{option.title}</span>
        </Categoria>
      </NavUI>
    ) : (
      option.options.map(option => (
        <NavUI key={`${id}-${option.title}`} to={`${option.href}${additionalQS ? `&${additionalQS}` : ''}`}>
          <Categoria>
            <span>{option.title}</span>
          </Categoria>
        </NavUI>
      ))
    );

  return (
    <ContainerCategory>
      <Title>{title}</Title>
      <Content ref={containerRef} containerHeight={containerHeight} showMore={showMore}>
        {optionsFiltered?.map(option => {
          const additionalQS = stringify(removeUndef({ rc, context }));
          const href = `${option.href}${additionalQS ? `&${additionalQS}` : ''}`;

          return renderCategory(option, href, additionalQS);
        })}
      </Content>

      {renderSeeMore(options)}
    </ContainerCategory>
  );
};

const ContainerCategory = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grey.light};
  padding: 8px 0;
`;

const Content = styled.div`
  max-height: 520px;
  transition: max-height 1s;
  overflow: hidden;

  ${({ showMore, containerHeight }) =>
    (showMore || containerHeight !== 0) &&
    css`
      max-height: ${containerHeight}px;
    `};
`;

const Title = styled.span`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-transform: lowercase;
  color: ${({ theme }) => theme.grey.dark};
  display: block;
  padding: 10px 0px;
`;

const NavUI = styled(Nav)`
  display: block;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const Categoria = styled.div`
  margin: 14px 0;

  span {
    font-size: ${({ theme }) => theme.fontSize.xs};
    text-transform: lowercase;
  }
`;

const SeeMore = styled.div`
  display: block;
  text-decoration: underline;
  padding: 6px 0;
  cursor: pointer;
`;

export default Category;
