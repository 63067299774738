import React from 'react';
import Loading from '@catalogo/ui-shoptime-mobile-loading';
import styled from 'styled-components';

const SearchLoading = () => (
  <Content>
    <Loading />
  </Content>
);

export default SearchLoading;

const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.3);
`;
