import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import ProductGrid from '@catalogo/ui-americanas-desktop-product-grid';
import SortBy from '@catalogo/ui-americanas-desktop-sort-by';
import SortByAlert from '@catalogo/ui-americanas-desktop-sort-by-alert';
import { getIn } from 'seamless-immutable';
import SelectedFilter from '@catalogo/ui-americanas-desktop-selected-filter';
import { Col } from '@catalogo/theme-grid';
import { hasCriticalErrors } from '@catalogo/core-graphql-errors';
import Error from '@catalogo/template-shoptime-desktop-error';
import Filters from '@catalogo/ui-americanas-desktop-filters';
import { withNewsletter } from '@catalogo/service-americanas-newsletter';
import { getTotalResultsText } from '@catalogo/ui-americanas-mobile-filter-menu/src/helpers';
import withNotFoundRedirect from '@catalogo/service-americanas-redirect/src/hoc/not-found';
import Loading from './components/loading';

export const Newsletter = ({
  data,
  parsedQs = {},
  history,
  experimentsResult,
  fetchMore,
  variables,
  hasNextPage,
  refetch,
  error,
  loading,
}) => {
  if (error && hasCriticalErrors(error.graphQLErrors, ['newsletter'])) {
    return <Error retry={() => refetch()} />;
  }

  const total = getIn(data, ['search', 'total'], '');
  const { selectedAggregations, aggregations } = getIn(data, ['search'], {});
  const products = getIn(data, ['search', 'products'], []);
  const productsIds = getIn(data, ['search', 'productIds'], []);
  const totalResults = getTotalResultsText(total);

  return (
    <Wrapper>
      {loading && <Loading />}
      {data && (
        <Container>
          <Col md={2}>
            <Filters filters={aggregations} history={history} modalHash="more-filters" />
          </Col>
          <Col md={10}>
            <SearchTop>
              <div>
                <TotalText>{totalResults}</TotalText>
              </div>
              <SortBy history={history} parsedQs={parsedQs} />
            </SearchTop>
            <SortByAlert parsedQs={parsedQs} />
            <SelectedFilter
              parsedQs={parsedQs}
              selectedAggregations={selectedAggregations}
              history={history}
              aggregations={aggregations}
              cleanAll={getIn(history, ['location', 'pathname'], '')}
            />
            <ProductGrid
              products={products}
              productsIds={productsIds}
              experiments={experimentsResult}
              fetchMore={fetchMore}
              parsedQs={parsedQs}
              variables={variables}
              hasNextPage={hasNextPage}
              history={history}
              total={total}
              skip={true}
            />
          </Col>
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: ${({ theme }) => theme.white};
  padding: 36px 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 12px;
  max-width: 1270px;
  margin: 0 auto;
`;

const SearchTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-top: solid 2px ${({ theme }) => theme.grey.lighter};
`;

const TotalText = styled.span`
  color: ${({ theme }) => theme.grey.dark};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

export default compose(withNewsletter, withNotFoundRedirect)(Newsletter);
