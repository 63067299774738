export const hasCriticalErrors = (errors = [], criticalErrors = []) => {
  const isServer = typeof window === 'undefined';

  return criticalErrors.some(critical =>
    errors.some(error => {
      if (!error.path) {
        return false;
      }

      const index = error?.path?.length - 1;

      if (error.status === 404 || error?.extensions?.status === 404) {
        return false;
      }

      const isCritical = error?.path?.[index] === critical;

      if (isServer && isCritical) {
        console.error(error);
      }

      return isCritical;
    })
  );
};

export const has404OnPath = (errors, criticalErrors) =>
  criticalErrors.some(critical =>
    errors.some(error => {
      const index = error?.path?.length - 1;

      const isCritical = error?.path?.[index] === critical;

      if (isCritical && error?.extensions?.status === 404) {
        return true;
      }

      return false;
    })
  );
