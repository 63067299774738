import { getIn } from 'seamless-immutable';
import { useQuery } from '@apollo/client';
import { getPlacements, mergeComponents } from '@catalogo/service-americanas-common/src/helpers/template';
import { getDeferedHotsite } from '../queries/defer.graphql';
import { pageHotsite } from '../queries/hotsite.graphql';
import { UseHotsiteProps } from '../types';

export const useHotsite = ({ routeId, parsedQs, match, dispatchMetrics }: UseHotsiteProps) => {
  const path = getIn(match, ['url']);
  const { sortBy = 'relevance', source = 'blancahotsite', limit = 24, offset = 0 } = parsedQs;
  const variables = {
    path,
    sortBy,
    source,
    limit: typeof limit === 'number' ? limit : parseInt(limit),
    offset: typeof offset === 'number' ? offset : parseInt(offset),
    routeId,
    sitepage: getIn(parsedQs, ['sitepage']) ? `/${getIn(parsedQs, ['sitepage'])}` : undefined,
  };

  const { loading, error, refetch, data, fetchMore } = useQuery(pageHotsite, {
    variables,
    context: routeId,
    onCompleted: /* istanbul ignore next */ data => dispatchMetrics('page:load', data),
  });

  const template = getIn(data, ['page', 'template'], {});
  const recPlacements = getPlacements('recommendation', template);
  const b2wAdsPlacements = getPlacements('b2wads', template);

  const { data: dataDefered } = useQuery(getDeferedHotsite, {
    variables: { recPlacements, b2wAdsPlacements, pageType: 'especial' },
    skip: recPlacements.length < 1 && b2wAdsPlacements.length < 1,
    context: routeId,
    ssr: false,
    onCompleted: dataDefered => dispatchMetrics('recommendation:load', mergeComponents(data, dataDefered)),
  });
  const allData = mergeComponents(data, dataDefered);
  const publications = getIn(allData, ['page', 'components'], {});

  return { publications, loading, error, refetch, data, fetchMore };
};
