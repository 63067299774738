import { parse } from 'querystring';
import { useQuery } from '@apollo/client';

import { mountFilters } from '../helpers.js';
import { pageNewsletter } from '../newsletter.graphql';
import { UseGetNewsletterDataProps } from '../types';

export const useGetNewsletterData = ({
  location,
  routeId,
  parsedQs = {},
  match,
  dispatchMetrics,
}: UseGetNewsletterDataProps) => {
  const { sortBy, offset = 0, limit } = parse(location.search.replace('?', ''));
  const variables = {
    routeId,
    offset: parseInt(offset as string),
    limit: limit ? parseInt(limit as string) : null,
    sortBy,
    oneDayDelivery: true,
    source: 'blancanewsletter',
    filters: mountFilters(parsedQs, match.params),
  };

  const { data, loading, error, refetch, fetchMore } = useQuery(pageNewsletter, {
    variables,
    onCompleted: /* istanbul ignore next */ data => dispatchMetrics('page:load', data),
  });

  return { loading, data, refetch, error, fetchMore };
};
