import React from 'react';
import styled from 'styled-components';
import Close from '@catalogo/ui-americanas-desktop-svg/close-bold.svg';
import { Nav } from '@catalogo/core-nav';

const SelectedFilter = ({ selectedAggregations, aggregations, cleanAll, bold }) => {
  const renderBoxFilter = ({ id, title, aggregationTitle, href }) => {
    const isPrice = aggregationTitle === 'Preço';
    return (
      <BoxFilter to={href} key={id} bold={bold}>
        <Filter isPrice={isPrice}>{title}</Filter>
        <IconClose fill="#666" width="10px" height="10px" />
      </BoxFilter>
    );
  };

  const getOneDayDelivery = () => aggregations.find(agg => agg.id === 'one_day_delivery');

  const oneDayDelivery = getOneDayDelivery();

  if (selectedAggregations && selectedAggregations.length === 0 && !oneDayDelivery) {
    return null;
  }

  const selectedAggWithoutODD = selectedAggregations.filter(selected => selected.id !== 'one_day_delivery');

  return (
    <Wrapper>
      {selectedAggWithoutODD.length !== 0 && (
        <>
          {selectedAggWithoutODD.map(filter => renderBoxFilter(filter))}
          <ClearButton push to={cleanAll}>
            limpar todos
          </ClearButton>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 5px 5px 8px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  border-bottom: solid 1px #ccc;
  &:empty {
    display: none;
  }
`;

const BoxFilter = styled(Nav)`
  background-color: ${({ theme }) => theme.white};
  width: auto;
  max-width: 180px;
  position: relative;
  padding: 8px 33px 8px 12px;
  white-space: nowrap;
  margin-bottom: 5px;
  margin-right: 8px;
  border: 1px solid #cccccc;
  border-radius: 17px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

const Filter = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  color: ${({ theme }) => theme.grey.dark};
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: ${({ isPrice }) => (isPrice ? null : 'lowercase')};
`;

const IconClose = styled(Close)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const ClearButton = styled(Nav)`
  position: relative;
  padding: 10px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.grey.darker};
  text-decoration: underline;
  text-transform: lowercase;
  cursor: pointer;
  margin-bottom: 5px;
`;

export default SelectedFilter;
