import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useQuery } from '@apollo/client';
import Position from './position';
import query from './query.graphql';
import { PageFromCacheQuery, Publication } from '../types/schema';

interface Publications {
  [component: string]: Publication;
}

interface Page {
  [component: string]: Publication;
}

interface Props extends RouteComponentProps {
  routeId: string;
  publications: Publications;
  area: string;
  page?: Page;
}

type TPropsObject = 'routeId' | 'publications' | 'area' | 'children';

type TBlackboxAreaComposed = React.FC<Pick<React.PropsWithChildren<Props>, TPropsObject>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BlackboxArea: React.FC<Props> = (props): React.PropsWithChildren<any> => {
  const { routeId, area, location } = props;

  const { data } = useQuery<PageFromCacheQuery>(query, {
    variables: {
      path: location.pathname,
      area,
    },
    fetchPolicy: 'cache-only',
  });

  const template = data?.page?.template?.area || null;

  if (!template) {
    return null;
  }

  return template.map(position => {
    const id = position?.id || '';
    const publication = props.publications[id];
    return (
      <Position key={id} area={area} position={id} routeId={routeId} publication={publication} page={props?.page} />
    );
  });
};

const BlackboxAreaComposed = (withRouter(BlackboxArea) as unknown) as TBlackboxAreaComposed;

export default BlackboxAreaComposed;
