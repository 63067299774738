import React from 'react';
import PropTypes from 'prop-types';

const Rating = props => (
  <svg viewBox="0 0 50 50" {...props}>
    <polygon
      fill="none"
      className={props.className}
      points="25,3.553 30.695,18.321 46.5,19.173   34.214,29.152 38.287,44.447 25,35.848 11.712,44.447 15.786,29.152 3.5,19.173 19.305,18.321 "
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

export default Rating;

Rating.propTypes = {
  className: PropTypes.string,
};
