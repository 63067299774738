import React from 'react';
import compose from 'lodash/fp/compose';

import { withMetrics } from '@catalogo/core-metrics';
import { withCookies } from '@catalogo/core-connect/cookies';
import { withNavigation } from '@catalogo/core-connect/navigation';
import { getIn } from 'seamless-immutable';

import { createSearch } from '@catalogo/service-americanas-newsletter/src/helpers.js';
import { useGetNewsletterData } from './hooks/newsletter';

export const withNewsletter = WrappedComponent => {
  const EnhancedNewsletter = ({
    history,
    location,
    routeId,
    parsedQs = {},
    match,
    cookies,
    dispatchMetrics,
    sortByList,
  }) => {
    const { loading, data, refetch, error, fetchMore } = useGetNewsletterData({
      location,
      routeId,
      parsedQs,
      match,
      cookies,
      dispatchMetrics,
    });

    const publications = getIn(data, ['page', 'components'], {});

    return (
      <WrappedComponent
        data={createSearch(data)}
        error={error}
        history={history}
        loading={loading}
        parsedQs={parsedQs}
        refetch={refetch}
        fetchMore={fetchMore}
        routeId={routeId}
        match={match}
        location={location}
        dispatchMetrics={dispatchMetrics}
        publications={publications}
        hasNextPage={getIn(data, ['search', 'hasNextPage'], false)}
        sortByList={sortByList}
      />
    );
  };

  return compose(withCookies, withMetrics, withNavigation)(EnhancedNewsletter);
};
