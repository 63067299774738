import React from 'react';
import { Text } from '@catalogo/theme-text';
import styled from 'styled-components';
import PropTypes from 'prop-types';

class Error extends React.Component {
  render() {
    const { retry } = this.props;

    return (
      <Wrapper>
        <Text>Ops aconteceu algum problema</Text>
        <Button onClick={retry}>Recarregar</Button>
      </Wrapper>
    );
  }
}

Error.propTypes = {
  retry: PropTypes.func,
};

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  display: block;
  margin: 50px auto;
  padding: 15px;
  border-radius: 5px;
  border: 0px;
  background: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  font-size: 16px;
`;

export default Error;
