import React from 'react';
import styled from 'styled-components';
import { getIn } from 'seamless-immutable';
import SortBy from '@catalogo/ui-americanas-desktop-sort-by';
import SortByAlert from '@catalogo/ui-americanas-desktop-sort-by-alert';
import ProductGrid from '@catalogo/ui-americanas-desktop-product-grid';
import { getTotalResultsText } from '@catalogo/ui-americanas-mobile-filter-menu/src/helpers';

const GridArea = ({ data, parsedQs, history, products, fetchMore, dispatchMetrics }) => {
  const total = getIn(data, ['search', 'total'], 0);
  const productsIds = getIn(data, ['search', 'productIds'], []);
  const hasNextPage = getIn(data, ['search', 'hasNextPage'], false);
  const term = getIn(data, ['search', 'term'], false);
  const totalResults = getTotalResultsText(total);

  return (
    <>
      <SearchTop>
        <TotalText>{totalResults}</TotalText>
        <SortBy history={history} parsedQs={parsedQs} />
      </SearchTop>
      <SortByAlert parsedQs={parsedQs} />
      <ProductGrid
        products={products}
        productsIds={productsIds}
        hasNextPage={hasNextPage}
        dispatchMetrics={dispatchMetrics}
        fetchMore={fetchMore}
        term={term}
        total={total}
        history={history}
        parsedQs={parsedQs}
      />
    </>
  );
};

const SearchTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-top: solid 2px #eee;
  margin-top: 20px;
`;

const TotalText = styled.span`
  color: #666;
  font-size: 14px;
`;

export default GridArea;
