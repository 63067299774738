import React from 'react';
import { Nav } from '@catalogo/core-nav';
import styled from 'styled-components';
import Alert from '@catalogo/ui-americanas-desktop-svg/icon-info.svg';
import { updatedURL, sendMetrics } from './helpers';

const SortByAlert = ({ parsedQs, dispatchMetrics }) => {
  const sortBy = parsedQs?.sortBy;

  if (sortBy === 'lowerPrice') {
    return (
      <Container>
        <AlertUI fill="#666" width="20px" height="20px" />
        <Message>
          Pra ter um melhor resultado,{' '}
          <NavUI
            to={updatedURL('lowerPriceRelevance', parsedQs)}
            onClick={() => sendMetrics(dispatchMetrics, 'menores-precos | ordenacao-combinada')}
          >
            combine a ordenação menores preços com mais relevantes.
          </NavUI>
        </Message>
      </Container>
    );
  }

  if (sortBy === 'higherPrice') {
    return (
      <Container>
        <AlertUI fill="#666" />
        <Message>
          Pra ter um melhor resultado,{' '}
          <NavUI
            to={updatedURL('higherPriceRelevance', parsedQs)}
            onClick={() => sendMetrics(dispatchMetrics, 'maiores-precos | ordenacao-combinada')}
          >
            combine a ordenação maiores preços com mais relevantes.
          </NavUI>
        </Message>
      </Container>
    );
  }

  if (sortBy === 'lowerPriceRelevance') {
    return (
      <Container>
        <AlertUI fill="#666" width="20px" height="20px" />
        <Message>
          Pra mostrar um melhor resultado, combinamos menores preços e mais relevantes. Se preferir, você pode{' '}
          <NavUI
            to={updatedURL('lowerPrice', parsedQs)}
            onClick={() => sendMetrics(dispatchMetrics, 'menores-precos | sem-ordenacao-combinada')}
          >
            ordenar apenas por menores preços.
          </NavUI>
        </Message>
      </Container>
    );
  }

  if (sortBy === 'higherPriceRelevance') {
    return (
      <Container>
        <AlertUI fill="#666" width="20px" height="20px" />
        <Message>
          Pra mostrar um melhor resultado, combinamos maiores preços e mais relevantes. Se preferir, você pode{' '}
          <NavUI
            to={updatedURL('higherPrice', parsedQs)}
            onClick={() => sendMetrics(dispatchMetrics, 'maiores-precos | sem-ordenacao-combinada')}
          >
            ordenar apenas por maiores preços.
          </NavUI>
        </Message>
      </Container>
    );
  }

  return null;
};

export default SortByAlert;

const Container = styled.div`
  background: #f2fbff;
  display: flex;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  align-items: flex-start;
  margin-top: 19px;
  @media (min-width: 768px) {
    align-items: center;
    margin-top: 0px;
  }
`;
const Message = styled.p`
  font-size: 12px;
  color: #333;
  margin-left: 16px;
`;

const AlertUI = styled(Alert)`
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
`;

const NavUI = styled(Nav)`
  text-decoration: underline;
`;
