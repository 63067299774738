import React from 'react';
import styled, { css } from 'styled-components';
import BlackboxArea from '@catalogo/core-blackbox/area';
import Error from '@catalogo/template-americanas-mobile-error';
import withHotsite from '@catalogo/service-americanas-hotsite/src';
import { Col } from '@catalogo/theme-grid';
import Filters from '@catalogo/ui-americanas-desktop-filters';
import SelectedFilter from '@catalogo/ui-americanas-desktop-selected-filter';
import { hasCriticalErrors } from '@catalogo/core-graphql-errors';
import ProductGrid from './components/grid-area';
import Loading from './components/loading';

export const Hotsite = props => {
  const {
    publications,
    loading,
    error,
    refetch,
    routeId,
    title,
    data,
    products,
    aggregations,
    parsedQs,
    history,
  } = props;

  if (error && hasCriticalErrors(error.graphQLErrors, ['search'])) {
    return <Error retry={() => refetch()} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      {data && Object.keys(data).length > 0 ? (
        <GridUI>
          <Col md={12}>
            {!!title && <Title>{title}</Title>}
            <BlackboxArea area="top" routeId={routeId} publications={publications} />
          </Col>
          {products?.length > 0 && (
            <ContainerGrid data-position="product-grid">
              {!!aggregations && (
                <Col md={2}>
                  <Filters
                    filters={aggregations}
                    history={history}
                    rc={parsedQs?.rc}
                    context={parsedQs?.context}
                    modalHash="more-filters"
                    {...props}
                  />
                </Col>
              )}
              <WrapperRight md={10}>
                <SelectedFilter {...props} />
                <ProductGrid {...props} />
              </WrapperRight>
            </ContainerGrid>
          )}
          <Col md={12}>
            <BlackboxArea area="middle" routeId={routeId} publications={publications} />
            <BlackboxArea area="bottom" routeId={routeId} publications={publications} />
          </Col>
        </GridUI>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.section``;
const ContainerGrid = styled.div`
  display: flex;
`;
const Title = styled.h1`
  ${({ theme }) => css`
    margin-bottom: 10px;
    text-transform: ${theme.titleTransform};
    font-weight: bold;
    color: ${theme.fontColor || theme.grey.dark};
    font-size: 22px;
    line-height: 30px;
    margin: 20px auto;
    width: 100%;
    max-width: 1250px;
    @media (min-width: 1025px) {
      font-size: 28px;
      line-height: 40px;
      margin: 15px auto;
      padding-left: 12px;
    }
  `}
`;

const WrapperRight = styled(Col)`
  margin: 0 auto;
  max-width: 1000px;
  padding: 10px 0 0;
`;

const GridUI = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  div[data-position] {
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
  }
`;

export default withHotsite(Hotsite);
