import React from 'react';
import styled from 'styled-components';
import { Aggregation, Category } from '@catalogo/ui-americanas-desktop-aggregation';

const Filters = ({ filters, history, rc, context, modalHash }) => {
  const renderAggregation = filter => {
    const filterProps = {
      rc,
      context,
      key: filter.id,
      history: history,
      modalHash,
      ...filter,
    };

    if (filter.id === 'one_day_delivery') {
      return null;
    }

    if (filter.id === 'categoria' || filter.id === 'category.id') {
      return <Category {...filterProps} id={filter.id} />;
    }

    return <Aggregation {...filterProps} id={filter.id} />;
  };

  return <Wrapper>{filters.map(renderAggregation)}</Wrapper>;
};

const Wrapper = styled.div`
  font-size: 13px;
  padding-right: 18px;
  max-width: 208px;
`;

export default Filters;
