import React from 'react';
import { Text } from '@catalogo/theme-text';
import styled from 'styled-components';
import SadFace from '@catalogo/ui-americanas-desktop-svg/sad-face.svg';

export const EmptyProducts = ({ content }) => (
  <Container>
    <IconWrapper>
      <Emoji />
    </IconWrapper>
    <TextWrapper>
      <Title>
        <TitleContent>
          <Red>Ops!</Red>&nbsp;nenhum resultado encontrado
          {content && (
            <>
              &nbsp;para <Red>{`"${content}"`}</Red>
            </>
          )}
          .
        </TitleContent>
      </Title>
      <TextUI>O que eu faço?</TextUI>
      <List>
        <ListItem>
          <Text>Verifique os termos digitados ou os filtros selecionados.</Text>
        </ListItem>
        <ListItem>
          <Text>Utilize termos genéricos na busca.</Text>
        </ListItem>
      </List>
    </TextWrapper>
  </Container>
);

const Container = styled.div`
  width: 100%;
  margin: 50px 0;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  @media (min-width: 768px) {
    flex-flow: row nowrap;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
`;

const Emoji = styled(SadFace)`
  width: 80px;
  height: 80px;
  fill: #cecece;
  margin-right: 20px;
`;

const TextWrapper = styled.div`
  width: 100%;
  flex: 0 1 auto;
  display: flex;
  flex-flow: column nowrap;

  @media (min-width: 768px) {
    width: auto;
  }
`;

const TextUI = styled.p`
  margin-bottom: 15px;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
`;

const Title = styled(TextUI)`
  font-weight: bold;
  font-size: 18px;
`;

const TitleContent = styled.span`
  @media (max-width: 767px) {
    text-align: center;
  }
`;

const List = styled.ul`
  @media (max-width: 767px) {
    display: table;
    margin: 0 auto;
    max-width: 325px;
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &::before {
    content: '';
    border: 4px solid ${({ theme }) => theme.primaryColor};
    border-radius: 20px;
    margin: 0px 10px;
  }

  @media (max-width: 767px) {
    align-items: baseline;
  }
`;

const Red = styled(Text)`
  color: ${({ theme }) => theme.primaryColor};
`;
