import React from 'react';
import styled from 'styled-components';
import { Grid, Col } from '@catalogo/theme-grid';
import productCardGridQuery from '@catalogo/service-americanas-product-card/src/queries/product-card-search.graphql';
import Pagination from '@catalogo/ui-americanas-mobile-pagination';
import { SwitchComponent } from '@catalogo/core-switch-component';
import { EmptyProducts } from '@catalogo/ui-americanas-mobile-product-grid/src/empty-products';

export const ProductGrid = ({ products, dispatchMetrics, parsedQs, history, term, ...props }) => {
  const offset = parsedQs?.offset || 0;
  const limit = parsedQs?.limit || 24;
  const hasProducts = products?.length > 0;

  if (!hasProducts) {
    return (
      <GridItem justifyContent="center">
        <EmptyProducts content={term} />
      </GridItem>
    );
  }

  return (
    <>
      <GridItem justifyContent="flex-start">
        {products?.map((product, index) =>
          product.product ? (
            <ColGridItem xs={4} sm={4} md={4} key={`${product?.product?.id}`}>
              <SwitchComponent
                index={index}
                product={product}
                query={productCardGridQuery}
                position="Grid Busca"
                component="new-product-card"
              />
            </ColGridItem>
          ) : null
        )}
      </GridItem>
      <BottomGrid>
        <Pagination
          maxButtons={8}
          history={history}
          parsedQs={parsedQs}
          dispatchMetrics={dispatchMetrics}
          offset={offset}
          limit={limit}
          {...props}
        />
      </BottomGrid>
    </>
  );
};
export default ProductGrid;

const GridItem = styled(Grid)`
  margin-top: 24px;
`;

const ColGridItem = styled(Col)`
  flex: 1 0 25%;
  margin: 0 24px 24px 0;
  &:nth-child(3n),
  &:last-child {
    border-right: none;
    margin-right: 0;
  }
  max-width: 315px;
`;

const BottomGrid = styled.div`
  margin: 36px 0;
`;
