import { parse } from 'querystring';
import React, { useState, useEffect } from 'react';
import { formatFilters, applyFilters, toggleFilter, isFilterSelected } from './helpers';

export const withFilters = Component => props => {
  const [filters, setFilters] = useState([]);
  const { history } = props;
  const { conteudo } = parse(history.location.search.replace('?', ''));

  useEffect(() => {
    const filtersFromQs = formatFilters(history.location.search);
    setFilters(filtersFromQs);
  }, [history.location]);

  return (
    <Component
      {...props}
      filters={filters}
      applyFilters={applyFilters(props, filters, conteudo)}
      toggleFilter={toggleFilter(filters, setFilters)}
      isFilterSelected={isFilterSelected(filters)}
    />
  );
};

export { formatFilters as formatFiltersFromQS, applyFilters };
