import { stringify } from 'querystring';

export const updatedURL = (sortParameter, parsedQs) => {
  const newQueryString = {
    ...parsedQs,
    sortBy: sortParameter,
  };

  return `?${stringify(newQueryString)}`;
};

export const sendMetrics = (dispatchMetrics, label) => {
  dispatchMetrics &&
    dispatchMetrics('event:track', {
      category: 'busca',
      action: 'flag-ordenar',
      label,
    });
};
