import React from 'react';
import { StatusCode, CustomStaticContext } from '@catalogo/core-status-code';
import { RouteComponentProps } from 'react-router';

export interface RedirectProps extends RouteComponentProps {
  status: number;
  url: string;
  staticContext?: CustomStaticContext;
}

export const Redirect: React.FC<RedirectProps> = props => {
  const StatusCodeComponent = (StatusCode as unknown) as React.FC<CustomStaticContext>;
  return <StatusCodeComponent status={props.status} url={props.url} />;
};
